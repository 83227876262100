document.addEventListener(
  "DOMContentLoaded",
  function () {
    const buttons = document.querySelectorAll(".sign-up-modal-button");
    const modal = document.querySelector(".modal-wrapper");
    const modalOverlay = document.querySelector(".modal-overlay");
    const close = document.getElementById("close-modal");

    buttons.forEach((button) => {
      button.addEventListener("click", function () {
        openModal();
      });
    });

    function openModal() {
      modal.classList.add("active");
      modalOverlay.classList.add("active");
      document.body.style.overflowY = "hidden";
    }

    function closeModal() {
      modal.classList.remove("active");
      modalOverlay.classList.remove("active");
      document.body.style.overflowY = "";
      banner = document.getElementById("modal-banner");
      banner.style.display = "none";
    }

    close.addEventListener("click", function () {
      closeModal();
    });

    window.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    });

    modalOverlay.addEventListener("click", function (event) {
      const isOutside = !event.target.closest(".modal");
      if (isOutside) {
        closeModal();
      }
    });
  },
  false
);

window.openSignUpModal = function(params, event_label) {
  event.preventDefault();
  const signupPath = `https://app.progressionapp.com/signup/new${params}`;

  document.getElementById(
    "oauth-sign-up-button"
  ).href = `${signupPath}&oauth=oauth-google-oauth2`;

  document.getElementById("email-sign-up-button").href = signupPath;

  gtag("event", "view_progression_signup", {
    event_category: "engagement",
    event_label,
  });
}
