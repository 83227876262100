document.addEventListener('DOMContentLoaded', function(){
  const waitlist = document.getElementById('waitlist')

  if (waitlist) {
    waitlist.addEventListener("submit", function(evt) {
      evt.preventDefault();
      let form_status = document.getElementById("waitlist-status")
      let form = document.getElementById("waitlist")
      let xhttp = new XMLHttpRequest();

      const fname = document.getElementById('fname').value
      const lname = document.getElementById('lname').value
      const email = document.getElementById('email').value
      const company = document.getElementById('company').value
      const role = document.getElementById('role').value
      const teamSize = document.getElementById('team-size').value
      const demoLink = `
        <div class="pb-2">
            <a data-savvycal-embed data-display-name="${fname} ${lname}" data-email="${email}" href="https://savvycal.com/progression/onboard" class="btn btn-green">Book a concierge signup session</a>
        </div>
        <div class="pb-3">
          <a href="https://app.progressionapp.com/signup/new" class="btn btn-green secondary">Skip and sign up now</a>
        </div>
        <p class="opacity-50">(We've also sent you these links by email)</p>
      `
      const signUpLink = `
        <div class="pb-3">
          <a href="https://app.progressionapp.com/signup/new" class="btn btn-green">Sign up</a>
        </div>
        <p class="opacity-50">(We've also sent you this link by email)</p>
      `
      const inviteMessage = `
        <h3 class="text-xl pb-1">Thanks ${fname}!</h3>
        <p class="text-lg pb-2">Let's get ${company} up and running on Progression.</p>
        <div class="flex flex-col justify-center items-center py-3">
          ${teamSize != "1-10" ? demoLink : signUpLink}
        </div>
      `

      const params = `fname=${encodeURIComponent(fname)}&lname=${encodeURIComponent(lname)}&email=${encodeURIComponent(email)}&company=${encodeURIComponent(company)}&role=${encodeURIComponent(role)}&teamSize=${encodeURIComponent(teamSize)}`

      xhttp.onreadystatechange = function() {
        form_status.classList.remove('bg-gray-50');
        if (this.readyState === 4 && this.status === 200) {
          form_status.classList.add('bg-green-100', 'border', 'border-green-300');
          form_status.innerHTML = inviteMessage
        } else {
          form_status.classList.add('bg-red-200','text-red-800', 'border', 'border-red-300');
          form_status.innerHTML = "Oops, something went wrong. Please email us at support@progressionapp.com for help."
        }
      };

      xhttp.open("POST", "/.netlify/functions/waitlist-submit", true);
      xhttp.send(params);
      form_status.innerHTML = "Progressing..."
      form_status.classList.add('bg-gray-50', 'p-3', 'md:p-6');
      form.setAttribute("style", "display:none")
    });

  }
})
